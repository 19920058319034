// TermsAndConditions.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './TermsAndConditions.css';

function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <div className="terms-conditions">
      <Helmet>
        <title>Plii - Terms and Conditions</title>
      </Helmet>

      {/* Back Button */}
      <button className="back-button" onClick={() => navigate(-1)}>
        &larr; Back
      </button>

      <h1>Terms and Conditions</h1>
      <iframe
        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=9a7c17a8-8e72-4be3-89fd-2ca631de7183"
        title="Terms and Conditions"
        width="100%"
        height="1000px"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
}

export default TermsAndConditions;
