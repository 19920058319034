import { gql } from '@apollo/client';

export const HANDLE_USER_ACTION = gql`
  mutation HandleUserAction($interactionID: ID!, $actionInput: ActionInput!) {
    handleUserAction(interactionID: $interactionID, actionInput: $actionInput) {
      message {
        role
        content
        createTimestamp
        actionPayload {
          taskID
          actionType
          choices {
            label
            choice
            multiSelectOptions {
              label
              choice
            }
          }
        }
      }
      completed
      archive
    }
  }
`;
