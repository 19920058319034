import React, { useState } from 'react';
import './MessageInput.css';

function MessageInput({ onSend, disabled }) {
  const [inputValue, setInputValue] = useState('');

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputValue.trim() === '') return;
    onSend(inputValue);
    setInputValue('');
  };

  return (
    <form className="message-input" onSubmit={handleSendMessage}>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Type your message..."
        disabled={disabled}
      />
      <button type="submit" disabled={disabled} />
    </form>
  );
}

export default MessageInput;
