import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { LOGOUT } from '../graphql/mutations/logout'
import './ProfileRow.css';

function ProfileRow({ profilePictureUrl, firstName, lastName }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted: (data) => {
      if (data.logout.success) {
        // Clear the cookie and any local storage/session data
        document.cookie = '__session=; Max-Age=0; path=/;';
        localStorage.clear();
        sessionStorage.clear();
        // Redirect or update UI
        window.location.href = '/?message=Logout+successful';
      } else {
        console.error('Logout failed:', data.logout.message);
        navigate('/?error=Logout+failed');
      }
    },
    onError: (error) => {
      console.error('Failed to execute logout:', error);
      navigate('/?error=Logout+failed');
    },
  });

  const logout = () => {
    logoutMutation();
  };

  const toggleMenu = (event) => {
    if (!menuOpen) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  };

  const handleLogout = () => logout();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div
      className={`profile-row-container ${menuOpen ? 'menu-opened' : ''}`}
      onClick={!menuOpen ? toggleMenu : undefined} /* Only toggle if closed */
    >
      <div className="profile-row">
        <img src={profilePictureUrl} alt="Profile" className="profile-pic" />
        <span className="profile-name">{`${firstName} ${lastName}`}</span>
      </div>

      {/* Menu positioned above and centered */}
      {menuOpen && (
        <div className="profile-menu-mobile" ref={menuRef}>
          <button onClick={handleLogout}>Log Out</button>
        </div>
      )}
    </div>
  );
}

export default ProfileRow;
