import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import Login from './components/Login';
import HomePage from './pages/HomePage';
import TermsAndConditions from './components/TermsAndConditions';
import CalendarIntegrationFlow from './components/CalendarIntegrationFlow';
import PrivacyPolicy from './components/PrivacyPolicy';
import { ToastContainer, toast } from 'react-toastify';
import LandingPage from './components/LandingPage'
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const localMode = false; 

// Define the GraphQL query to check the session
const CHECK_SESSION = gql`
  query CheckSession {
    checkSession {
      authenticated
      user {
        email
        firstName
        lastName
        profilePictureUrl
        onboardingStatus {
          completed
        }
      }
    }
  }
`;

// Utility function to delete a cookie by name
function deleteCookie(name) {
  document.cookie = `${name}=; Max-Age=0; path=/;`;
}

// Utility function to clear query parameters
function clearQueryParams() {
  const newUrl = window.location.origin + window.location.pathname;
  window.history.replaceState({}, document.title, newUrl);
}

function App() {
  const { data, loading, error } = useQuery(CHECK_SESSION, {
    fetchPolicy: 'network-only',
    skip: localMode, // Skip the query execution when localMode is true
  });
  const [isAuthenticated, setIsAuthenticated] = useState(localMode ? true : null);
  const [user, setUser] = useState(localMode ? {
    email: 'localuser@example.com',
    firstName: 'Local',
    lastName: 'User',
    profilePictureUrl: 'https://via.placeholder.com/150',
    onboardingStatus: { completed: true },
  } : null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const toastError = queryParams.get('error');
    const toastMessage = queryParams.get('message');

    if (toastError && toastMessage) {
      toast.error(decodeURIComponent(toastMessage), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true, // Removes the timer/progress bar
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      clearQueryParams();
    } else if (toastMessage) {
      toast.success(decodeURIComponent(toastMessage), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true, // Removes the timer/progress bar
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      clearQueryParams();
    }
  }, [location.search]);

  useEffect(() => {
    if (!localMode && !loading && data && data.checkSession) {
      setIsAuthenticated(data.checkSession.authenticated);
      setUser(data.checkSession.user);

      if (!data.checkSession.authenticated) {
        deleteCookie('__session');
        console.log("Cookie deleted due to unauthenticated session.");
      }
    } 
    if (!loading && error) {
      setIsAuthenticated(false);
    }
  }, [loading, data]);

  if (error) {
    console.error("Error in GraphQL session check:", error);
//    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/auth/login" element={<Login />} />
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <HomePage user={user} />
            ) : (
              <LandingPage /> // Show LandingPage for unauthenticated users
            )
          }
        />
        <Route
          path="/calendar-integration"
          element={
            isAuthenticated ? (
              <CalendarIntegrationFlow />
            ) : (
              <Navigate to="/auth/login" replace />
            )
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </>
  );
}

export default App;

