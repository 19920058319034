import { gql } from '@apollo/client';

export const CREATE_INTERACTION = gql`
  mutation CreateInteraction($messages: [NewMessageInput]) {
    createInteraction(messages: $messages) {
      id
      title
      messages {
        role
        content
        createTimestamp
        actionPayload {
          taskID
          actionType
          choices {
            label
            choice
            multiSelectOptions {
              label
              choice
            }
          }
        }
      }
    }
  }
`;