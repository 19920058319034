import { gql, useQuery } from '@apollo/client';

export const GET_CALENDAR_CONNECTIONS = gql`
  query GetCalendarConnections {
    getCalendarConnections {
      provider
      calendars {
        id
        name
      }
    }
  }
`;
