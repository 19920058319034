import React, { useState } from 'react';
import './CalendarSelectionModal.css';

function CalendarSelectionModal({ calendars, loading, onSelect }) {
  const [selectedCalendars, setSelectedCalendars] = useState([]);

  const handleCheckboxChange = (calendarId) => {
    setSelectedCalendars((prev) =>
      prev.includes(calendarId)
        ? prev.filter((id) => id !== calendarId)
        : [...prev, calendarId]
    );
  };

  const handleSubmit = () => {
    if (selectedCalendars.length > 0) {
      onSelect(selectedCalendars);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h3>Select Calendars to Track</h3>
        {loading ? (
          <p>Loading calendars...</p>
        ) : (
          <ul>
            {calendars.map((calendar) => (
              <li key={calendar.id}>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedCalendars.includes(calendar.id)}
                    onChange={() => handleCheckboxChange(calendar.id)}
                  />
                  {calendar.name}
                </label>
              </li>
            ))}
          </ul>
        )}
        <button
          className="primary-button"
          onClick={handleSubmit}
          disabled={selectedCalendars.length === 0} // Disable if no selection
        >
          Save Selection
        </button>
      </div>
    </div>
  );
}

export default CalendarSelectionModal;
