import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_INTERACTION } from '../graphql/mutations/createInteraction';
import { HANDLE_NEW_USER_MESSAGE } from '../graphql/mutations/handleNewUserMessage';
import { HANDLE_USER_ACTION } from '../graphql/mutations/handleUserAction';
import { GET_USER_EXTERNAL_CALENDAR_LIST } from '../graphql/queries/getUserExternalCalendarsList';
import { ADD_EXTERNAL_CALENDARS } from '../graphql/mutations/addExternalCalendars';
import { GET_USER_ONBOARDING_STATUS } from '../graphql/queries/getUserOnboardingStatus';
import ChatWindow from '../components/ChatWindow';
import MessageInput from '../components/MessageInput';
import Sidebar from '../components/Sidebar';
import ProfileBubble from '../components/ProfileBubble'; 
import CalendarSelectionModal from '../components/CalendarSelectionModal';
import './HomePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function HomePage({ user }) {
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [interactionID, setInteractionID] = useState(localStorage.getItem('interactionID') || null);
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('messages');
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [source, setSource] = useState(null);
  // const [messages, setMessages] = useState([
  //   {
  //     id: 0,
  //     content: "Welcome, Han! Please select an option below.",
  //     sender: "assistant",
  //     actionPayload: {
  //       taskID: "task_123",
  //       actionType: "SELECT_OPTION",
  //       choices: [
  //         {
  //           label: "Select Times",
  //           choice: "SELECT_TIMES",
  //           multiSelectOptions: [
  //             { choice: "morning", label: "Morning" },
  //             { choice: "afternoon", label: "Afternoon" },
  //             { choice: "evening", label: "Evening" },
  //           ],
  //         },
  //         {
  //           label: "Select Date",
  //           choice: "SELECT_DATE",
  //         },
  //       ],
  //     },
  //   },
  // ]);  
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to control sidebar visibility
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // State to check if it's mobile
  const [loading, setLoading] = useState(false);
  const [resetting, setResetting] = useState(false);

  const [getOnboardingStatus] = useLazyQuery(GET_USER_ONBOARDING_STATUS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      user.onboardingStatus = data.getUserOnboardingStatus;
      console.log("fetched onboarding status: ", data.getUserOnboardingStatus.completed);
    },
    onError: (error) => {
      console.error('Failed to fetch onboarding status:', error);
    },
  });

  // Mutation to create a new interaction
  const [createInteraction] = useMutation(CREATE_INTERACTION, {
    onCompleted: (data) => {
      const newInteraction = data.createInteraction;
      setInteractionID(newInteraction.id);
      setMessages((prevMessages) => [
        ...prevMessages,
        ...newInteraction.messages.map((msg, index) => ({
          id: prevMessages.length + index + 1,
          content: msg.content,
          sender: msg.role === 'user' ? 'user' : 'assistant',
          actionPayload: msg.actionPayload,
          actionTaken: false,
        })),
      ]);
      setLoading(false);
      setResetting(false);
    },
    onError: (error) => {
      console.error('Failed to create interaction:', error);
      setLoading(false);
    },
  });

  const resetInteraction = () => {
    setResetting(true); // Trigger fade-out animation
    setTimeout(() => {
      setMessages([]); // Clear the current messages
      setInteractionID(null);
      createInteraction(); // Start a new interaction after fade-out
    }, 1000);
  };

  const [handleNewUserMessage] = useMutation(HANDLE_NEW_USER_MESSAGE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('Full Response:', data);
      if (data?.handleNewUserMessage?.message) {
        const newMessage = data.handleNewUserMessage.message;
        const archive = data.handleNewUserMessage.archive;
        console.log(newMessage);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            content: newMessage.content,
            sender: 'assistant',
            actionPayload: newMessage.actionPayload || null,
            actionTaken: false,
          },
        ]);
        if (archive) {
          resetInteraction();
          getOnboardingStatus();
        }
      }
      setLoading(false);
    },
    onError: (error) => {
      console.error('Failed to send message:', error);
      setLoading(false);
    },
  });

  const handleSendMessage = (messageContent) => {
    if (!interactionID) return;
    setLoading(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: prevMessages.length + 1,
        content: messageContent,
        sender: 'user',
      },
    ]);

    handleNewUserMessage({
      variables: {
        interactionID,
        message: { content: messageContent },
      },
    });
  };

  const [handleUserAction] = useMutation(HANDLE_USER_ACTION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('Full Response:', data);
      const newMessage = data.handleUserAction.message;
      const archive = data.handleUserAction.archive;

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          content: newMessage.content,
          sender: 'assistant',
          actionPayload: newMessage.actionPayload,
          actionTaken: false,
        },
      ]);
      setLoading(false);
      if (archive) resetInteraction();
    },
    onError: (error) => {
      console.error('Failed to handle action:', error);
      setLoading(false);
    },
  });

  const handleActionClick = (taskID, actionType, choice, multiSelectOptions) => {
    setLoading(true); 
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.actionPayload?.taskID === taskID
          ? { ...msg, actionTaken: true }
          : msg
      )
    );
    if (actionType === 'CALENDAR_INTEGRATION') {
      localStorage.setItem('pendingTaskID', taskID);
      localStorage.setItem('pendingChoice', choice);
      localStorage.setItem('interactionID', interactionID);
      localStorage.setItem('messages', JSON.stringify(messages));
      window.location.href = '/api/auth/calendar';
    } else {
      handleUserAction({
        variables: {
          interactionID,
          actionInput: {
            taskID,
            choice,
            multiSelectOptions,
          },
        },
        onCompleted: () => setLoading(false),
      });
    }
  };

  const handleCalendarAuthComplete = (calendarSource) => {
    const storedInteractionID = localStorage.getItem('interactionID');
    const storedMessages = localStorage.getItem('messages');
    setSource(calendarSource);

    if (storedInteractionID && storedMessages) {
      setInteractionID(storedInteractionID);
      setMessages(JSON.parse(storedMessages));
    }

    setShowCalendarModal(true); // Open modal for calendar selection
    getCalendars({ variables: { source: calendarSource } });
  };

  // Query to fetch user's external calendar list
  const [getCalendars, { data: calendarData, loading: loadingCalendars }] = useLazyQuery(
    GET_USER_EXTERNAL_CALENDAR_LIST,
    {
      fetchPolicy: 'network-only',
    }
  );

  function clearQueryParams() {
    const newUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);
  }

  const [addExternalCalendars] = useMutation(ADD_EXTERNAL_CALENDARS, {
    onCompleted: (data) => {
      if (data.addExternalCalendars.success) {
        const storedTaskID = localStorage.getItem('pendingTaskID');
        const storedChoice = localStorage.getItem('pendingChoice');
        const storedInteractionID = localStorage.getItem('interactionID');    
    
        // Only call handleUserAction if calendars were added successfully
        handleUserAction({
          variables: {
            interactionID: storedInteractionID, // Replace with appropriate value or state
            actionInput: {
              taskID: storedTaskID,             // Replace with appropriate value or state
              choice: storedChoice,             // Replace with appropriate value or state
            },
          },
        });
        localStorage.removeItem('pendingTaskID');
        localStorage.removeItem('pendingChoice');
        localStorage.removeItem('interactionID');
        localStorage.removeItem('messages');
        setLoading(true);
        setShowCalendarModal(false); // Close modal
      } else {
        console.error("Failed to add calendars.");
      }
    },
    onError: (error) => {
      console.error('Failed to add selected calendars:', error);
    },
  });

  const handleCalendarSelection = (selectedCalendarIds) => {
    addExternalCalendars({
      variables: {
        source,
        ids: selectedCalendarIds,
      },
    });
  };

  useEffect(() => {
    const initializeInteraction = async () => {
      const savedInteractionID = localStorage.getItem('interactionID');
      const savedMessages = localStorage.getItem('messages');

      if (savedInteractionID && savedMessages) {
        setInteractionID(savedInteractionID);
        setMessages(JSON.parse(savedMessages));
      } else {
        setLoading(true);
        await createInteraction();  // Creates a new interaction if none is saved
      }
    };

    // TODO: Change this flow
    const authSuccess = new URLSearchParams(window.location.search).get('calendarAuthSuccess');
    const calendarSource = new URLSearchParams(window.location.search).get('source');
    clearQueryParams();
    if (authSuccess) {
      console.log("Modal opened, fetching calendars..."); 
      handleCalendarAuthComplete(calendarSource);
    } else {
      setLoading(true);
      initializeInteraction();
    }

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', checkMobile);
    checkMobile();
    return () => window.removeEventListener('resize', checkMobile);
  }, [createInteraction]);

  // useEffect(() => {
  //   if (interactionID && messages.length > 0) {
  //     localStorage.setItem('interactionID', interactionID);
  //     localStorage.setItem('messages', JSON.stringify(messages));
  //   }
  // }, [messages, interactionID]);

  const toggleSidebar = () => setSidebarOpen(prev => !prev);
  const closeSidebar = () => setSidebarOpen(false); 
  const handleOverlayClick = () => {
    if (isMobile) closeSidebar();
  };

  const userMessages = messages.filter((message) => message.sender === 'user');

  return (
    <div className={`home-container ${sidebarOpen ? 'sidebar-open' : ''}`}>
      <div className="navbar">
        {!sidebarOpen && isMobile && user.onboardingStatus.completed && (
          <button className="compose-button" onClick={resetInteraction}><FontAwesomeIcon icon={faPen} /></button>
        )}
        {user.onboardingStatus.completed && <div className="hamburger-menu" onClick={toggleSidebar}>☰</div>}
        {!isMobile && user.onboardingStatus.completed && (
          <ProfileBubble profilePictureUrl={user.profilePictureUrl} />
        )}
      </div>
      {showCalendarModal && (
        <CalendarSelectionModal
          calendars={calendarData?.getUserExternalCalendarList || []}
          loading={loadingCalendars}
          onSelect={handleCalendarSelection}
          onClose={() => setShowCalendarModal(false)}
        />
      )}
      {sidebarOpen && isMobile && <div className="overlay" onClick={handleOverlayClick}></div>}
      {user.onboardingStatus.completed && <Sidebar
        isOpen={sidebarOpen}
        closeSidebar={closeSidebar}
        profilePictureUrl={isMobile ? user.profilePictureUrl : null}
        user={isMobile ? user : null}
        isMobile={isMobile}
        resetInteraction={resetInteraction}
      />}
      <div className={`right-chat-container ${resetting ? 'fade-out' : 'fade-in'}`}>
        <ChatWindow messages={messages} showLoadingIndicator={loading} onAction={handleActionClick} />
        <MessageInput onSend={handleSendMessage} disabled={loading || resetting || !interactionID} />
      </div>
    </div>
  );
}

export default HomePage;
