import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_CALENDAR_CONNECTIONS } from '../graphql/queries/getCalendarConnections';
import { GET_USER_EXTERNAL_CALENDAR_LIST } from '../graphql/queries/getUserExternalCalendarsList';
import { ADD_EXTERNAL_CALENDARS } from '../graphql/mutations/addExternalCalendars';
import ProviderSelectionScreen from './ProviderSelectionScreen';
import CalendarSelectionModal from './CalendarSelectionModal';

function CalendarIntegrationFlow() {
  const location = useLocation();
  const navigate = useNavigate();

  // Query for initial connections
  const { data: connectionsData, loading: connectionsLoading, error: connectionsError } = useQuery(GET_CALENDAR_CONNECTIONS);

  // Lazy query to fetch external calendar list after redirect
  const [fetchExternalCalendars, { data: externalCalendarsData, loading: externalCalendarsLoading, error: externalCalendarsError }] = useLazyQuery(GET_USER_EXTERNAL_CALENDAR_LIST);

  // Mutation to add selected calendars
  const [addExternalCalendars] = useMutation(ADD_EXTERNAL_CALENDARS);

  const [showProviderSelection, setShowProviderSelection] = useState(true);
  const [showCalendarSelection, setShowCalendarSelection] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [connectedAccounts, setConnectedAccounts] = useState([]);

  // Initialize connected accounts when connectionsData is available
  useEffect(() => {
    if (connectionsData?.getCalendarConnections) {
      const initialConnections = connectionsData.getCalendarConnections.map((connection) => ({
        provider: connection.provider,
        calendars: connection.calendars.map((cal) => ({ id: cal.id, name: cal.name })),
      }));
      setConnectedAccounts(initialConnections);
    }
  }, [connectionsData]);

  // Parse query parameters on component mount or location change
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const provider = params.get('provider');
    if (provider) {
      setSelectedProvider(provider);
      setShowProviderSelection(false);
      setShowCalendarSelection(true);
      fetchExternalCalendars(); // Fetch external calendars after redirect
    }
  }, [location.search, fetchExternalCalendars]);

  const handleProviderSelect = (provider) => {
    // Redirect for authentication; server will handle redirect back with the provider query param
    window.location.href = `/api/auth/calendar?provider=${provider}`;
  };

  const handleCalendarSelection = async (selectedCalendarIds) => {
    try {
      const result = await addExternalCalendars({
        variables: {
          source: selectedProvider,
          ids: selectedCalendarIds,
        },
      });
      if (result.data.addExternalCalendars.success) {
        // Update connected accounts with selected calendars
        setConnectedAccounts((prev) => [
          ...prev,
          { provider: selectedProvider, calendars: selectedCalendarIds.map((id) => connectedAccounts.find((cal) => cal.id === id)) },
        ]);
        setShowCalendarSelection(false);
        setShowProviderSelection(true); // Return to provider selection screen
        navigate('/calendar-integration'); // Clear query params after successful addition
      } else {
        console.error("Failed to add calendars.");
      }
    } catch (error) {
      console.error("Error adding calendars:", error);
    }
  };

  if (connectionsLoading) return <p>Loading initial connections...</p>;
  if (connectionsError) return <p>Error loading connections: {connectionsError.message}</p>;

  return (
    <>
      {showProviderSelection && (
        <ProviderSelectionScreen
          connectedAccounts={connectedAccounts}
          onProviderSelect={handleProviderSelect}
        />
      )}
      {showCalendarSelection && (
        <CalendarSelectionModal
          provider={selectedProvider}
          calendars={externalCalendarsData?.getUserExternalCalendarList || []}
          loading={externalCalendarsLoading}
          onSelect={handleCalendarSelection}
          onClose={() => setShowCalendarSelection(false)}
        />
      )}
      {externalCalendarsError && <p>Error loading external calendars: {externalCalendarsError.message}</p>}
    </>
  );
}

export default CalendarIntegrationFlow;
