import React, { useRef, useEffect } from 'react';
import ProfileRow from './ProfileRow';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

function Sidebar({ isOpen, closeSidebar, profilePictureUrl, user, isMobile, resetInteraction }) {
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobile && isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, closeSidebar]);

  return (
    <div ref={sidebarRef} className={`left-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="close-sidebar-button" onClick={closeSidebar}>☰</div>
      <button className="sidebar-compose-button" onClick={resetInteraction}><FontAwesomeIcon icon={faPen} /></button>

      {/* Other Sidebar content here */}

      {/* ProfileRow fixed at the bottom */}
      {isOpen && user && (
        <div className="sidebar-bottom">
          <ProfileRow
            profilePictureUrl={profilePictureUrl}
            firstName={user.firstName}
            lastName={user.lastName}
          />
        </div>
      )}
    </div>
  );
}

export default Sidebar;
