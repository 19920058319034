// PrivacyPolicy.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <div className="privacy-policy">
      <Helmet>
        <title>Plii - Privacy Policy</title>
      </Helmet>

      {/* Back Button */}
      <button className="back-button" onClick={() => navigate(-1)}>
        &larr; Back
      </button>

      <h1>Privacy Policy</h1>
      <iframe
        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=29ef3fcd-49a5-418f-9349-7b22296f5ea3"
        title="Privacy Policy"
        width="100%"
        height="1000px"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
}

export default PrivacyPolicy;
