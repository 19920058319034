import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';

// Create Apollo Client with credentials included
const client = new ApolloClient({
  link: new HttpLink({
    uri: '/api/graphql', // Updated GraphQL server URL
    credentials: 'include', // Ensure that cookies are included with the request
  }),
  cache: new InMemoryCache(),
});

client.cache.reset();

// Wrap App with both ApolloProvider and Router
ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);