import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './LandingPage.css';
import appLogo from '../assets/logo.svg';

function LandingPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;

    try {
      const response = await fetch('/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate(`${location.pathname}?message=${encodeURIComponent(data.message)}`, { replace: true });
      } else if (response.status === 400) {
        navigate(`${location.pathname}?error=true&message=${encodeURIComponent(data.message || 'Invalid email address.')}`, { replace: true });
      } else {
        navigate(`${location.pathname}?error=true&message=${encodeURIComponent('An unexpected error occurred. Please try again later.')}`, { replace: true });
      }
    } catch (error) {
      console.error('Error subscribing email:', error);
      navigate(`${location.pathname}?error=true&message=${encodeURIComponent('An error occurred while subscribing. Please try again later.')}`, { replace: true });
    }
  };

  const scrollToSignup = () => {
    document.getElementById('signup').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>Empowering Parents Through Connected Communities</title>
      </Helmet>

      {/* Sticky Navbar */}
      <nav id="navbar">
        <div className="nav-content">
          <div className="logo-content">
            <img src={appLogo} alt="Company Logo" className="logo" />
          </div>
          <button onClick={scrollToSignup}>Sign Up</button>
        </div>
      </nav>

      {/* Hero Section */}
      <section id="hero">
        <div id="hero-content">
          <h1>Empowering Parents Through Real Connections</h1>
                      <p>An AI-driven platform for parents, creating supportive networks wherever you are.</p>
        </div>
      </section>

      {/* Problem Statement Section */}
      <section id="problem">
        <h2>Parenting journey is better together. But how do you build ‘together’?</h2>
        <p>
        Juggling family life and finding genuine support is tough. Our platform effortlessly connects like-minded parents, online and nearby—no pressure, just real connections.
        </p>
      </section>

      {/* Features as Separate Sections */}
      <section id="feature-1">
        <h2>Verified Parent Networks</h2>
        <p>
          Connect with trusted parents in your neighborhood or school communities.
          Build a support system that you can rely on.
        </p>
      </section>

      <section id="feature-2">
        <h2>AI-Assisted Coordination</h2>
        <p>
          Easily schedule playdates and family activities with smart coordination.
          Save time and reduce the hassle of planning.
        </p>
      </section>

      <section id="feature-3">
        <h2>Sharing with Parents Like You</h2>
        <p>
        Share and receive insights from similar parents — whether it’s kid-friendly recommendations, local activity feedback, or even sensitive issues — all made easy and safe with AI.
        </p>
      </section>

      {/* Benefits Highlight */}
      <section id="benefits">
        <h2>Why Choose Plii?</h2>
        <ul>
          <li>Simplify your family schedule</li>
          <li>Reduce daily stressors</li>
          <li>Build lasting connections</li>
          <li>Access curated parenting insights</li>
        </ul>
      </section>

      {/* Email Signup (Repeated at the Bottom) */}
      <section id="signup">
        <h2>Join Our Community</h2>
        <p>Sign up to be the first to know when we launch.</p>
        <form onSubmit={handleSubmit}>
          <input type="email" name="email" placeholder="Email Address" required />
          <button type="submit">Notify Me</button>
        </form>
        <p className="privacy-note">We respect your privacy and won't share your information.</p>
      </section>

      {/* Footer */}
      <footer>
        <p>&copy; 2024 plii LLC. All rights reserved.</p>
        <p><a href="/privacy-policy">Privacy Policy</a>| 
        <a href="/terms-and-conditions">Terms and Conditions</a></p>
      </footer>
    </>
  );
}

export default LandingPage;
