import React from 'react';
import './Login.css';

function Login() {
  const handleLogin = () => {
    // Log cookies before redirecting
    console.log("Cookies before redirecting to Google login:", document.cookie);

    // Redirect to Google OAuth login
    window.location.href = '/api/auth/login';
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h1>Welcome to Pathy</h1>
        <p>Please log in to continue</p>
        <button onClick={handleLogin}>Login with Google</button>
      </div>
    </div>
  );
}

export default Login;
