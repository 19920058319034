import { gql } from '@apollo/client';

export const HANDLE_NEW_USER_MESSAGE = gql`
  mutation HandleNewUserMessage($interactionID: ID!, $message: NewMessageInput!) {
    handleNewUserMessage(interactionID: $interactionID, message: $message) {
      message {
        role
        content
        createTimestamp
        actionPayload {
          taskID
          actionType
          choices {
            label
            choice
            multiSelectOptions {
              label
              choice
            }
          }
        }
      }
      completed
      archive
    }
  }
`;
