import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { LOGOUT } from '../graphql/mutations/logout';
import './ProfileBubble.css';

function ProfileBubble({ profilePictureUrl, isSidebarOpen }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted: (data) => {
      if (data.logout.success) {
        // Clear the cookie and any local storage/session data
        document.cookie = '__session=; Max-Age=0; path=/;';
        localStorage.clear();
        sessionStorage.clear();
        // Redirect or update UI
        window.location.href = '/?message=Logout+successful';
      } else {
        console.error('Logout failed:', data.logout.message);
        navigate('/?error=Logout+failed');
      }
    },
    onError: (error) => {
      console.error('Failed to execute logout:', error);
      navigate('/?error=Logout+failed');
    },
  });

  const logout = () => {
    logoutMutation();
  };

  const toggleMenu = () => {
    if (!isSidebarOpen && !menuOpen) {
      setMenuOpen(true);
    } else if (menuOpen) {
      setMenuOpen(false);
    }
  };

  // Function to navigate to calendar integration flow
  const handleCalendarIntegration = () => {
    navigate('/calendar-integration');
    setMenuOpen(false); // Close menu after navigating
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <div className="profile-desktop-container">
      <div className={`profile-bubble-desktop ${menuOpen ? 'menu-opened' : ''}`} onClick={!menuOpen ? toggleMenu : undefined} >
        <img src={profilePictureUrl} alt="Profile" className="profile-bubble-pic" />
      </div>
      {menuOpen && (
        <div className="profile-menu-desktop" ref={menuRef}>
          <button onClick={handleCalendarIntegration}>Calendar Integration</button>
          <button onClick={logout}>Log out</button>
        </div>
      )}
    </div>
  );
}

export default ProfileBubble;
