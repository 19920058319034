import { gql } from '@apollo/client';

export const GET_USER_EXTERNAL_CALENDAR_LIST = gql`
  query GetUserExternalCalendarList($source: String!) {
    getUserExternalCalendarList(source: $source) {
      id
      source
      name
    }
  }
`;