import React from 'react';

function ProviderSelectionScreen({ connectedAccounts, onProviderSelect, onComplete }) {
  return (
    <div className="provider-selection">
      <h2>Select a Calendar Provider</h2>
      
      <ul>
        {['Google'].map((provider) => (
          <li key={provider}>
            <button onClick={() => onProviderSelect(provider)}>{provider}</button>
          </li>
        ))}
      </ul>

      {connectedAccounts.length > 0 && (
        <div>
          <h3>Connected Accounts</h3>
          <ul>
            {connectedAccounts.map((account, index) => (
              <li key={index}>{account.provider} - {account.email}</li>
            ))}
          </ul>
        </div>
      )}

      <button onClick={onComplete} className="complete-button">Done</button>
    </div>
  );
}

export default ProviderSelectionScreen;
